<template>
    <div class="landingContainer">
        <h1 class="pt-5 text-center text-black font-weight-bold">{{ $t('serviceContractSection.title') }}</h1>

        <div class="d-md-flex justify-content-center">

            <div class="col d-flex justify-content-center align-items-center" ref="requestFormContainer">
                <card class="col col-md-12 containerForm mx-3">
                    <span>{{ $t('serviceContractSection.mandatoryFields') }}</span>
                    <hr>
                    <form @submit.prevent="sendContract">

                        <base-input name="rep_full_name" v-model="form.rep_full_name"
                            :label="`${$t('nameAndLastname')}*`" :placeholder="$t('nameAndLastname')" required
                            :classes="form.rep_full_name !== null && !form.rep_full_name ? 'border border-danger' : ''" />

                        <base-input name="rep_dni" v-model="form.rep_dni"
                            :label="`${$t('serviceContractSection.dni')}*`"
                            :placeholder="$t('serviceContractSection.dni')" required
                            :classes="form.rep_dni !== null && !checkDNIIsValid() ? 'border border-danger' : ''" />

                        <base-input name="company_name" v-model="form.company_name"
                            :label="`${$tc('serviceContractSection.business', 1)}*`"
                            :placeholder="$tc('serviceContractSection.business', 1)" required
                            :classes="form.company_name !== null && !form.company_name ? 'border border-danger' : ''" />

                        <base-input name="company_cif" v-model="form.company_cif"
                            :label="`${$t('serviceContractSection.cif')}`"
                            :placeholder="$t('serviceContractSection.cif')"
                            :classes="!checkCIFIsValid() ? 'border border-danger' : ''" />

                        <base-input inputType="address" name="address" v-model="form.company_address"
                            :label="`${$t('serviceContractSection.address')}*`"
                            :placeholder="$t('serviceContractSection.address')" required
                            :classes="form.company_address !== null && !form.company_address ? 'border border-danger' : ''" />

                        <base-input name="zip" v-model="form.zip" inputType="number"
                            :label="`${$t('serviceContractSection.zip')}*`"
                            :placeholder="$t('serviceContractSection.zip')" required
                            :classes="form.zip !== null && !form.zip ? 'border border-danger' : ''" />

                        <base-input name="town" v-model="form.town" :label="`${$t('serviceContractSection.town')}*`"
                            :placeholder="$t('serviceContractSection.town')" required
                            :classes="form.town !== null && !form.town ? 'border border-danger' : ''" />

                        <base-input name="state" v-model="form.state" :label="`${$t('serviceContractSection.state')}*`"
                            :placeholder="$t('serviceContractSection.state')" required
                            :classes="form.state !== null && !form.state ? 'border border-danger' : ''" />

                        <base-input inputType="tel" name="phone" v-model="form.phone" :label="`${$t('phone')}*`"
                            :placeholder="$t('phone')" required
                            :classes="form.phone !== null && !form.phone ? 'border border-danger' : ''" />

                        <base-input inputType="email" name="email" v-model="form.email" :label="`${$t('email')}*`"
                            :placeholder="$t('email')" required
                            :classes="form.email !== null && checkEmailIsInvalid() ? 'border border-danger' : ''" />

                        <base-input inputType="text" name="account_number" v-model="form.account_number"
                            :label="`${$t('accountNumber')}*`" :placeholder="$t('accountNumber')" required
                            :classes="form.account_number !== null && !isValidAccount ? 'border border-danger' : ''"
                            @input="checkAccountNumberIsInvalid()" />

                        <base-input inputType="text" name="code" v-model="form.code" :label="`${$t('referralCode')}`"
                            :placeholder="$t('referralCode')" :disabled="isReferer" />
                        <div class="d-flex align-items-center mb-4">
                            <base-checkbox v-model="form.premium" class="mb-2">
                                <div v-html="$t('premiumLabel')"></div>

                            </base-checkbox>
                        </div>

                        <VueSignaturePad id="signature" width="100%" height="15em" ref="signature" required
                            :options="signOptions" :class="sending && !form.signature ? 'border border-danger' : ''" />

                        <base-button class="my-2 btn-simple d-flex justify-content-center" size="lg" @click="signClear"
                            block>
                            <span>{{ $t('serviceContractSection.clearSignature') }}</span>
                        </base-button>

                        <div class="d-flex align-items-center mt-2">
                            <base-checkbox v-model="form.dataTreatment" class="mb-2">
                                <span>{{ $t("acceptConditionsOfThe") }}</span>
                            </base-checkbox>
                            <span class="checkboxLink" @click="openPdf('dataTreatment')">
                                {{ $t('dataTreatment') }}
                            </span>
                        </div>

                        <div class="d-flex align-items-center mb-4">
                            <base-checkbox v-model="form.serviceContract" class="mb-2">
                                <span>{{ $t("acceptConditionsOfThe") }}</span>
                            </base-checkbox>
                            <span class="checkboxLink" @click="openPdf('serviceAgreement')">
                                {{ $t('serviceContract') }}
                            </span>
                        </div>

                        <base-button native-type="submit" :disabled="disabledBtn" type="success"
                            class="mb-2 startTestBtn d-flex justify-content-center" size="lg" block>
                            <span>{{ $t('signContract') }}</span>
                            <Spinner v-if="sending" classes="text-info ml-4"></Spinner>
                        </base-button>
                    </form>
                </card>
            </div>
        </div>

        <section class="my-5">
            <Testimonials />
        </section>

        <section class="my-5">
            <ToolsApp />
        </section>

        <div class="m-5 text-center">
            <p class="text-dark phrase-cta my-2">{{ $t('earnMoreWithYourBusiness') }}</p>

            <div class="block d-md-flex justify-content-center">
                <base-button type="success" class="startFreeTrialBtn block mb-2 col col-sm-10 col-md-6 col-lg-4 "
                    size="lg" @click="goTo('requestFormContainer')">
                    <span>{{ $t('startFreeTrial') }}</span>
                </base-button>
            </div>
        </div>
    </div>
</template>

<script>
import { BaseInput, Card, Spinner, Testimonials, ToolsApp } from "@/components/index";
import { mapState } from 'vuex';
import { iban } from '@lemoncode/fonk-iban-validator';
import { createFormValidation } from "@lemoncode/fonk";

export default {
    data() {
        return {
            form: {
                rep_full_name: null,
                rep_dni: null,
                company_name: null,
                company_cif: null,
                zip: null,
                town: null,
                state: null,
                phone: null,
                email: null,
                company_address: null,
                code: null,
                signature: null,
                dataTreatment: false,
                serviceContract: false,
                account_number: null,
            },
            sending: false,
            signIsEmpty: true,
            isViewDesktop: false,
            isReferer: false,
            signOptions: {
                minWidth: 2,
                maxWidth: 6,
                onEnd: () => this.signChange()
            },
            validationSchema: {
                field: { account: [iban.validator] }
            },
            isValidAccount: false
        }
    },
    computed: {
        disabledBtn() {
            const requiredFields = ['rep_full_name', 'rep_dni', 'company_name', 'zip', 'town', 'state', 'phone', 'email', 'company_address', 'dataTreatment', 'serviceContract', 'account_number'];

            return this.sending || this.signIsEmpty || requiredFields.some(field => !this.form[field])
                || this.checkEmailIsInvalid() || !this.isValidAccount || !this.checkDNIIsValid() || !this.checkCIFIsValid();
        },
        ...mapState(["defaultTitle"]),
    },
    methods: {
        async sendContract() {
            this.sending = true;
            const { data } = this.$refs.signature.saveSignature();
            this.form.signature = data;
            const route = '/stores/service-contract';

            let form = { ...this.form };

            if (!form.code) delete form.code;
            if (!form.company_cif) delete form['company_cif'];

            this.form['account_number'] = this.form['account_number'].replace(/ /g, '');

            try {
                const response = await this.axios.post(route, form)
                if (response && response.data.status === "success") {
                    this.$toast.success(this.$t("notifications.success.contact"));
                    this.signClear();
                    this.formClear();
                }
            } catch (error) {
                this.$toast.error(this.$t("notifications.error.tryLater"));
                console.log(error);
            } finally {
                this.sending = false;
            }
        },
        checkViewDesktop() {
            this.isViewDesktop = window.innerWidth >= 768;
        },
        goTo(destiny) {
            if (this.$refs && this.$refs[destiny]) {
                this.$refs[destiny].scrollIntoView({ block: "center", behavior: "smooth" })
            }
        },
        checkEmailIsInvalid() {
            const mailFormat = /^[\w-.]+@([\w-]+\.)+([\w-]+)$/g;
            return !mailFormat.test(this.form.email);
        },
        async checkAccountNumberIsInvalid() {
            const formValidation = createFormValidation(this.validationSchema);
            Promise.all([formValidation.validateField("account", this.form.account_number)]).then(([{ succeeded: is_valid_account }]) => this.isValidAccount = is_valid_account);
        },
        checkDNIIsValid() {
            if (!this.form.rep_dni) return;

            let number, docLetter, letter;
            const dni_reg_exp = /^[XYZ]?\d{5,8}[A-Z]$/;

            const dniOrNie = this.form.rep_dni.toUpperCase();

            if (dni_reg_exp.test(dniOrNie) === true) {
                number = dniOrNie.substr(0, dniOrNie.length - 1);
                number = number.replace('X', 0);
                number = number.replace('Y', 1);
                number = number.replace('Z', 2);
                docLetter = dniOrNie.substr(dniOrNie.length - 1, 1);
                number = number % 23;
                letter = 'TRWAGMYFPDXBNJZSQVHLCKET';
                letter = letter.substring(number, number + 1);
                return letter == docLetter
            } else {
                return false;
            }

        },
        checkCIFIsValid() {
            if (!this.form.company_cif) {
                return true;
            }
            if (this.form.company_cif.length !== 9) {
                return false;
            }

            const letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
            const digits = this.form.company_cif.substr(1, this.form.company_cif.length - 2);
            const letter = this.form.company_cif.substr(0, 1);
            const control = this.form.company_cif.substr(this.form.company_cif.length - 1);
            let sum = 0, i, digit;

            if (!letter.match(/[A-Z]/)) {
                return false;
            }

            for (i = 0; i < digits.length; ++i) {
                digit = parseInt(digits[i]);

                if (isNaN(digit)) {
                    return false;
                }

                if (i % 2 === 0) {
                    digit *= 2;
                    if (digit > 9) {
                        digit = parseInt(digit / 10) + (digit % 10);
                    }

                    sum += digit;
                } else {
                    sum += digit;
                }
            }

            sum %= 10;
            if (sum !== 0) {
                digit = 10 - sum;
            } else {
                digit = sum;
            }

            if (letter.match(/[ABEH]/)) {
                return String(digit) === control;
            }
            if (letter.match(/[NPQRSW]/)) {
                return letters[digit] === control;
            }

            return String(digit) === control || letters[digit] === control;
        },
        setGroups() {
            let tools = ["appointmentManagement", "reminders", "statistics", "controlEmployees", "managerFunctions", "loyalty", "CMS", "protectionPolicies"]
            this.groups = tools.map(item => {
                let group = {
                    isActive: false,
                    title: this.$t(`marketingSection.descriptionTools.${item}.title`),
                    description: this.$t(`marketingSection.descriptionTools.${item}.description`),
                }
                return group
            })
        },
        signClear() {
            this.$refs.signature.clearSignature();
            this.signChange()
        },
        formClear() {
            this.form = {
                rep_full_name: null,
                rep_dni: null,
                company_name: null,
                company_cif: null,
                zip: null,
                town: null,
                state: null,
                phone: null,
                email: null,
                company_address: null,
                code: null,
                signature: null,
                dataTreatment: false,
                serviceContract: false,
                account_number: null,
            };
        },
        signChange() {
            const { isEmpty } = this.$refs.signature.saveSignature();
            this.signIsEmpty = isEmpty;
        },
        async openPdf(pdfName) {
            const urlFile = {
                dataTreatment: './pdf/LPD-AS-SALOON.pdf',
                serviceAgreement: './pdf/Acuerdo-servicio-AS-SALOON.pdf'
            }

            window.open(urlFile[pdfName], '_blank').focus();
        }
    },
    metaInfo() {
        return {
            title: `${this.$t('footerLink.joinUs')} - ${this.defaultTitle}`,
            meta: [
                { name: 'og:title', property: 'og:title', content: `${this.$t('footerLink.joinUs')} - ${this.defaultTitle}` },
                { name: 'og:type', property: 'og:type', content: 'website' },
                { name: 'og:url', property: 'og:url', content: `${process.env.VUE_APP_URL.substring(0, process.env.VUE_APP_URL.length - 1)}${this.$route.fullPath}` },
                { name: 'og:description', property: 'og:description', content: this.$t('vueMeta.serviceContract.description') },
                { name: 'description', content: this.$t('vueMeta.serviceContract.description') },
                { name: 'og:site_name', property: 'og:site_name', content: process.env.VUE_APP_AS_NAME }
            ]
        }
    },
    components: {
        BaseInput,
        Card,
        Spinner,
        Testimonials,
        ToolsApp
    },
    created() {
        window.addEventListener("resize", this.checkViewDesktop);
    },
    mounted() {
        const queryRef = this.$route.query.code;
        const scrollTop = 0;
        if (window.scrollY !== scrollTop) window.scrollTo(0, 0);

        this.checkViewDesktop();

        if (queryRef) {
            this.form.code = queryRef;
            this.isReferer = true;
        }
    },
    destroyed() {
        window.removeEventListener("resize", this.checkViewDesktop);
    },
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/white-dashboard.scss';

#signature {
    border: solid 1px rgba(34, 42, 66, 0.5);
    border-radius: 0.4285rem;
    cursor: crosshair;
}

.containerForm {
    border-radius: 1em;
}

.phrase {
    font-size: 1.6em;
    font-weight: bold;
    line-height: 3.5rem;
}

.phrase-cta {
    font-size: 2.2em;
    font-weight: bold;
}

.startFreeTrialBtn {
    height: 5em;
    font-size: 1.5em;
}

.startFreeTrialBtn>span,
.startTestBtn>span {
    white-space: break-spaces;
}

.card-cto {
    border: 2px solid $primary;
}

.landing-img {
    width: 100%;
    max-width: 500px;
    margin: 0 auto 1rem;
}

.landingContainer {
    max-width: 1500px;
    margin: 0 auto;

    .header-icons-texts {
        font-weight: normal;
        font-size: 15px;
    }

    .bg-white {
        border-radius: 1rem;
    }

    .text-cta {
        h2 {
            color: #088375;
            margin: 10px 0;
            font-size: 2rem;
        }

        h3 {
            margin-bottom: 10px !important;
        }
    }

    .description-text {
        font-weight: 400 !important;

        span {
            font-weight: bolder !important;
        }
    }
}

.checkboxLink {
    font-weight: bold;
    font-size: 0.75rem;
    margin-left: 2px;
    margin-top: 1px;
    cursor: pointer;
}

@media (min-width: 768px) {
    .title-text {
        font-size: 1.3rem;
        font-weight: 600;
        text-align: left;
    }

    .description-text {
        font-size: 1rem;
    }
}
</style>